<template>
    <div>
      <time-type-list></time-type-list>>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  